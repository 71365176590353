@import '~@angular/material/theming';

// Custom Theming and Variables for OneAngular Kit
@import '~@one/angular-kit/styles/theming';
// Include the OneDesign Icons. We include this here so that you only
// have to load a single css file for OneDesign Icons in your app.
// Be sure that you only ever include this mixin once!
@import "~@one/icons/dist/one-icons-legacy.css";

// This will automatically load in the Roboto Fontface.
// In case you want to use a different font, make sure to update
// they "body" tag and remove this line.
@import '~roboto-fontface/css/roboto/roboto-fontface.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include one-core();

// Additionally modifies components for the OneAngularKit
@include one-angular-theme();

// Added to resolve icon issue
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html,
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: $one-color-blue-100;
}

/*
 * To be deleted when issue is solved in OneAngular
 * https://code.roche.com/onedesign/angular-kit/issues/53
 */
.mat-dialog-content[oneDialogContent] {
  @media screen and (max-width: 599px) {
    min-width: unset;
  }
}
// To wrap the text in snackbar in mobile devices
one-snack-bar {
  .one-snack-bar-container {
    @media screen and (max-width: 599px) {
      min-width: unset !important;
    }
  }
}
// This is required here for live chat's tool tip styling
.mat-tooltip {
  font-size: 11px !important;
  margin-top: -2px !important;
}

// This is to set the width for side nav in desktop as per the prototype
.mat-drawer-inner-container {
  @media screen and (min-width: 599px) {
    width: 227px !important;
  }
}
// set background color in logged in view
mat-sidenav-content {
  background-color: $one-color-blue-100;
}

.page-content{
  margin: 24px;
}